/*
 * @Author: zhangkai
 * @Date: 2021-09-13 17:14:37
 */
 
 export default [
  {
    id: '@budget-adjust',
    path: '/budget-adjust',
    ref: '/',
    onload: () => import('./budget-adjust-view'),
  },
  {
    point: '@@layers',
    prefix: '@budget-adjust',
    onload: () => import('./layers'),
  },
];